import React from 'react';

import { IoChatbubbleEllipses } from 'react-icons/io5';

import { Button } from '@/components/ui/button';

const SupportButton = ({ isOpen }: { isOpen: boolean }) => {
  const handleSupportClick = () => {
    if (typeof window !== 'undefined' && 'zE' in window) {
      (window as any).zE('webWidget', 'show');
      (window as any).zE('webWidget', 'open');
      (window as any).zE('webWidget:on', 'close', () => {
        (window as any).zE('webWidget', 'hide');
      });
    }
  };

  return (
    <div
      className={`flex flex-col text-sivarbet-background bg-[radial-gradient(circle,_rgb(149,251,63)_60%,_rgb(70,252,109)_100%)] my-5 rounded-xl hover:bg-[radial-gradient(circle,_rgb(149,251,63)_60%,_rgb(70,252,109)_100%)]/50 ${!isOpen && 'items-center'}`}
    >
      <Button
        type="button"
        className={`flex text-sivarbet-background items-center justify-start w-full bg-transparent gap-2 py-1 px-3 hover:bg-inherit rounded-xl transition duration-200 ${!isOpen && 'justify-center'}`}
        onClick={handleSupportClick}
      >
        <IoChatbubbleEllipses className="text-lg text-sivarbet-background" />
        {isOpen && <span>Soporte</span>}
      </Button>
    </div>
  );
};

export default SupportButton;
