/** @format */

'use client';

import React, { useEffect, useState } from 'react';

import { useRouter } from 'next/navigation';
import Script from 'next/script';

import { AnimatePresence, motion } from 'framer-motion';
import { CiGift } from 'react-icons/ci';
import { GiMoneyStack } from 'react-icons/gi';

import {
  IoHomeOutline,
  IoPlayCircleOutline,
  IoStarOutline,
} from 'react-icons/io5';

import CasinoTab from '@/features/sidebar/CasinoTab';
import SportsTab from '@/features/sidebar/SportTab';
import SupportButton from '@/features/sidebar/SupportButton';
import { userUIStore } from '@/stores/userUIStore';

import { OptionsSidebar } from '@/interfaces/core';

import { Tabs, TabsList, TabsTrigger } from '@/components/ui/tabs';

import LinkItem from '../items/LinkItem';
import { ScrollArea } from '../ui/scroll-area';

const SideNavbar = () => {
  const { isSidebarOpen, toggleSidebar } = userUIStore();
  const router = useRouter();
  const [currentPath, setCurrentPath] = useState('');
  const arrOptions: OptionsSidebar[] = [
    {
      title: 'Baloncesto',
      icon: 'basketball',
      type: 'sport',
      href: '/deportes?leagueLink=championship/2980',
    },
    {
      title: 'Hokey',
      icon: 'hockey',
      type: 'sport',
      href: '/deportes?leagueLink=championship/3232',
    },
    {
      title: 'Tenis',
      icon: 'tennil',
      type: 'sport',
      href: '/deportes?leagueLink=championship/3158',
    },
    {
      title: 'Esports',
      icon: 'joystick',
      type: 'sport',
      href: '/deportes#/esports',
    },
    {
      title: 'Arcade',
      href: '/casino/?arcade=casual',
      icon: 'joystick',
      type: 'casino',
    },
    {
      title: 'Slots',
      href: '/casino/?slot=slots',
      icon: 'slot',
      type: 'casino',
    },
    {
      title: 'Loteria',
      href: '/casino/?lottery=lottery',
      icon: <GiMoneyStack className="text-2xl self-center" />,
      type: 'casino',
    },
    {
      title: 'Juegos de Cartas',
      href: '/casino/?card=card',
      icon: 'cards',
      type: 'casino',
    },
    {
      title: 'Juegos Crash',
      href: '/casino/?crash=crash',
      icon: 'dart',
      type: 'casino',
    },
  ];

  useEffect(() => {
    if (typeof window !== 'undefined') {
      setCurrentPath(window.location.pathname);
    }
  }, [currentPath, toggleSidebar]);

  const sidebarVariants = {
    open: { width: '18rem' }, // 72px in rem
    closed: { width: '5rem' }, // 80px in rem
  };

  const contentVariants = {
    open: { opacity: 1, x: 0 },
    closed: { opacity: 0, x: -10 },
  };

  return (
    <>
      <Script
        id="ze-snippet"
        src="https://static.zdassets.com/ekr/snippet.js?key=1164835f-2b22-42cb-9d5c-197511b1d9f5"
        strategy="lazyOnload"
        onLoad={() => {
          if (typeof window.zE !== 'undefined') {
            window.zE('webWidget', 'hide');
          }
        }}
      />
      <motion.div
        data-testid="sidebar"
        className="max-md:hidden fixed z-10 px-3 h-full bg-sivarbet-secondary-bg text-sivarbet-text"
        initial={isSidebarOpen ? 'closed' : 'open'}
        animate={isSidebarOpen ? 'closed' : 'open'}
        variants={sidebarVariants}
        transition={{ duration: 0.4, ease: 'easeInOut' }}
      >
        <div
          data-collapsed={isSidebarOpen}
          className="group flex items-center flex-col gap-4 py-2 text-sivarbet-text h-full w-full"
        >
          <ScrollArea className="h-full">
            <nav className="grid gap-1 px-2 h-full">
              <Tabs
                value={
                  currentPath.includes('/casino')
                  || currentPath.includes('/favoritos') ? 'casino' : 'sport'
                }
                className="h-full"
              >
                <TabsList
                  className={`w-full ${isSidebarOpen ? 'flex flex-col items-center mt-20 h-[80px]' : 'grid grid-cols-2 mt-20 pb-12'} bg-sivarbet-background rounded-xl text-sivarbet-text`}
                >
                  <TabsTrigger
                    value="sport"
                    className="flex-center-active data-[state=active]:bg-sivarbet-primary"
                    onClick={() => { return router.push('/deportes#/overview'); }}
                  >
                    <span className="mx-1 fill-white icon icon-ball" />
                    <AnimatePresence>
                      {!isSidebarOpen && (
                        <motion.span
                          initial="closed"
                          animate="open"
                          exit="closed"
                          variants={contentVariants}
                          transition={{ duration: 0.2 }}
                        >
                          Deportes
                        </motion.span>
                      )}
                    </AnimatePresence>
                  </TabsTrigger>
                  <TabsTrigger
                    value="casino"
                    className="flex-center-active data-[state=active]:bg-sivarbet-primary"
                    onClick={() => { return router.push('/casino'); }}
                  >
                    <span className="mx-1 fill-white icon icon-cards" />
                    <AnimatePresence>
                      {!isSidebarOpen && (
                        <motion.span
                          initial="closed"
                          animate="open"
                          exit="closed"
                          variants={contentVariants}
                          transition={{ duration: 0.2 }}
                        >
                          Casino
                        </motion.span>
                      )}
                    </AnimatePresence>
                  </TabsTrigger>
                </TabsList>
                <div
                  className={`flex flex-col bg-sivarbet-background my-5 rounded-xl ${isSidebarOpen && 'items-center'} `}
                >
                  {/* Use AnimatePresence and motion.div for each LinkItem */}
                  <AnimatePresence>
                    <motion.div
                      initial="closed"
                      animate="open"
                      exit="closed"
                      variants={contentVariants}
                      transition={{ duration: 0.2 }}
                    >
                      <LinkItem
                        link={{
                          title: 'Inicio',
                          href: '/',
                          icon: (
                            <IoHomeOutline
                              className={`${!isSidebarOpen && 'mr-2'} self-center h-4 w-4 text-xl`}
                            />
                          ),
                          type: 'general',
                        }}
                        isCollapsed={isSidebarOpen}
                      />
                      <LinkItem
                        link={{
                          title: 'Eventos en vivo',
                          href: '/deportes#/live',
                          icon: (
                            <IoPlayCircleOutline
                              className={`${!isSidebarOpen && 'mr-2'} self-center h-4 w-4 text-xl`}
                            />
                          ),
                          type: 'general',
                        }}
                        isCollapsed={isSidebarOpen}
                      />
                      <LinkItem
                        key={2}
                        link={{
                          title: 'Promociones',
                          href: '/promotions',
                          icon: (
                            <CiGift
                              className={`${!isSidebarOpen && 'mr-2'} self-center h-4 w-4 text-xl`}
                            />
                          ),
                          type: 'general',
                        }}
                        isCollapsed={isSidebarOpen}
                      />
                      <LinkItem
                        key={3}
                        link={{
                          title: 'Torneos',
                          href: '/torneos',
                          icon: 'trophy',
                          type: 'general',
                        }}
                        isCollapsed={isSidebarOpen}
                      />
                      <LinkItem
                        key={4}
                        link={{
                          title: 'Favoritos',
                          href: '/favoritos',
                          icon: (
                            <IoStarOutline
                              className={`${!isSidebarOpen && 'mr-2'} self-center h-4 w-4 text-xl`}
                            />
                          ),
                          type: 'general',
                        }}
                        isCollapsed={isSidebarOpen}
                      />
                    </motion.div>
                  </AnimatePresence>
                </div>
                <SportsTab isOpen={!isSidebarOpen} arrOptions={arrOptions} />
                <CasinoTab isOpen={!isSidebarOpen} arrOptions={arrOptions} />
                <SupportButton isOpen={!isSidebarOpen} />
              </Tabs>
            </nav>
          </ScrollArea>
        </div>
      </motion.div>
    </>
  );
};

export default SideNavbar;
