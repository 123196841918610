'use client';

// import React, { useState } from 'react';

import { zodResolver } from '@hookform/resolvers/zod';

import { useForm } from 'react-hook-form';

import { z } from 'zod';

// import { InputBirthdate } from '@/features/dashboard/components/Identity/Inputs';

// import { arrayBankDepositForm } from '@/features/deposit/utils';
// import { errors, messages } from '@/localization';

// import { uploadFileToS3 } from '@/utils/core';

// import { Button } from '@/components/ui/button';
// import {
//   Form, FormControl, FormField, FormItem,
//   FormMessage,
// } from '@/components/ui/form';
// import { Input } from '@/components/ui/input';
// import { useToast } from '@/components/ui/use-toast';

// import BankDepositResume from './BankDepositResume';
import { bankVoucherForm } from '../../dashboard/schema';

interface Props {
  setModalToShow: (data:String) => void,
}

const BankDepositForm = ({ setModalToShow }: Props) => {
  // const [currentStep, setCurrentStep] = useState<String>('step1');
  // const [isEditable, setIsEditable] = useState<boolean>(true);
  // const { toast } = useToast();
  // const [file, setFile] = useState<any>(null);
  // const [voucherData, setVoucherData] = useState<any>({
  //   deposit_date: new Date().toDateString(), value: 0, bank: 0, number: '', description: '', document_url: '',
  // });

  const yesterday = new Date();
  yesterday.setDate(yesterday.getDate() - 2);

  // const handleFileChange = (e:any) => {
  //   const newFile = e.target.files[0];
  //   setFile(newFile);
  // };

  const form = useForm<z.infer<typeof bankVoucherForm>>({
    resolver: zodResolver(bankVoucherForm),
    defaultValues: {
      bank: '',
      date: '',
      voucher: '',
      value: '',
      observations: '',
      file: '',
    },
    mode: 'onChange',
  });

  // const handleSubmitForm = async (values: z.infer<any>): Promise<void> => {
  //   try {
  //     if (!file) {
  //       alert('Debes seleccionar un archivo!');
  //       return;
  //     }

  //     if (file) {
  //       await uploadFileToS3(file, (status:any) => { console.log('status => 108 => ', status); });
  //     }

  //     setIsEditable(false);

  //     const data = {
  //       ...values,
  //       date: new Date(values.date.split('/').reverse().join('-')).toISOString(),
  //       file: `https://sivarbetkyc.s3.eu-north-1.amazonaws.com/${file.name}`,
  //       bank: Number(values.bank),
  //       value: Number(values.value),
  //     };
  //     const response = await fetch('/api/voucher', {
  //       method: 'POST',
  //       headers: {
  //         'Content-Type': 'application/json',
  //       },
  //       body: JSON.stringify(data),
  //     });
  //     const result = await response.json();

  //     if (result.success) {
  //       setCurrentStep('step2');
  //       toast({
  //         title: messages.requestSuccess,
  //         description: 'Se han guardado los cambios',
  //         variant: 'default',
  //         className: 'bg-sivarbet-secondary',
  //       });
  //       setVoucherData({
  //         bank: data.bank,
  //         value: data.value,
  //         deposit_date: data.date,
  //         observations: data.observations,
  //         document_url: data.file,
  //       });
  //     } else {
  //       toast({
  //         title: errors.processFailed,
  //         description: result.message,
  //         variant: 'destructive',
  //       });
  //     }
  //   } catch (error) {
  //     toast({
  //       title: errors.processFailed,
  //       variant: 'destructive',
  //     });
  //   }
  // };

  // const renderCurrentStep = () => {
  //   switch (currentStep) {
  //     case 'step3':
  //       return (
  //         <section>
  //           <div className="transaction-message-wrapper p-6">
  //             <h2 className="text-center">Mensaje transacción 2154254</h2>
  //           </div>
  //           <Button
  //             onClick={() => { setModalToShow('ContentDeposit'); }}
  //             type="submit"
  //             className="block w-full rounded-lg bg-sivarbet-primary overflow-hidden flex-row items-center justify-center py-3 px-5 text-center text-sivarbet-border hover:bg-sivarbet-primary-hover"
  //           >
  //             <div className="relative text-sm leading-3 font-sans text-sivarbet-border text-center inline-block min-w-16">
  //               Siguiente
  //             </div>
  //           </Button>
  //         </section>
  //       );
  //     case 'step2':
  //       return (
  //         <BankDepositResume voucher={voucherData} setCurrentStep={() => { setCurrentStep('step3'); }} />
  //       );
  //     default:
  //       return (
  //         <section className="w-full bg-[#101422] p-4 md:p-8 flex flex-row flex-wrap items-center justify-end gap-[20px_16px] min-h-[220px] max-w-full text-left text-sm text-sivarbet-text font-text-sm-medium rounded-lg">
  //           <Form
  //             watch={form.watch}
  //             getValues={form.getValues}
  //             getFieldState={form.getFieldState}
  //             setError={form.setError}
  //             clearErrors={form.clearErrors}
  //             setValue={form.setValue}
  //             trigger={form.trigger}
  //             formState={form.formState}
  //             resetField={form.resetField}
  //             reset={form.reset}
  //             handleSubmit={form.handleSubmit}
  //             unregister={form.unregister}
  //             control={form.control}
  //             register={form.register}
  //             setFocus={form.setFocus}
  //           >
  //             <form className="w-full flex flex-wrap gap-4" onSubmit={form.handleSubmit(handleSubmitForm)}>
  //               {arrayBankDepositForm().map((item) => {
  //                 return (
  //                   <div key={item.id} className={`w-full ${item.fullWidth ? 'sm:w-full' : 'sm:w-[calc(50%-0.5rem)]'} flex flex-col gap-[10px]`}>
  //                     <label className="leading-5 font-medium" htmlFor={item.id}>
  //                       {item.label}
  //                     </label>
  //                     {
  //                       item.disabled ? (
  //                         <div
  //                           className="w-full bg-gray h-[48px] rounded-lg flex flex-row items-center px-3.5 font-text-sm-medium font-medium text-sm !text-slategray"
  //                         >
  //                           { form.getValues(item.id as 'bank' | 'date' | 'voucher' | 'value' | 'observations') }
  //                         </div>
  //                       ) : (
  //                         <FormField
  //                           control={form.control}
  //                           name={item.id as 'bank' | 'date' | 'voucher' | 'value' | 'observations'}
  //                           render={({ field }) => {
  //                             return (
  //                               <FormItem className="w-full">
  //                                 <FormControl>
  //                                   <>
  //                                     {item.id === 'file' && (
  //                                       <div>
  //                                         <input name="file" type="file" onChange={handleFileChange} />
  //                                       </div>
  //                                     )}
  //                                     {item.id === 'bank' && (
  //                                       <div className="flex items-center bg-gray rounded-lg h-[48px] w-full focus:bg-[#0A0E1A] focus:border-white">
  //                                         <select onChange={field.onChange} name="bank" className="w-full p-2 rounded-md input-form-text h-10 px-2">
  //                                           <option value="0">Selecciona un banco</option>
  //                                           <option value="1">Banpais</option>
  //                                           <option value="2">Bac</option>
  //                                         </select>
  //                                       </div>
  //                                     )}
  //                                     {item.id === 'date' && (
  //                                       <InputBirthdate
  //                                         onChange={(e) => {
  //                                           const value = typeof e === 'string' ? e : e.toString();
  //                                           form.clearErrors('date');
  //                                           form.setValue('date', value);
  //                                         }}
  //                                         placeholder={item.placeholder?.toString() || ''}
  //                                         value={field.value?.toString() || ''}
  //                                         isEditable={isEditable}
  //                                         pastLimit={yesterday.toString()}
  //                                       />
  //                                     )}
  //                                     { item.id !== 'bank' && item.id !== 'date' && item.id !== 'file' && (
  //                                       <Input
  //                                         className={`w-full ${item.id === 'observations' ? 'h-12' : '!h-[48px]'} ![border:none] ![outline:none] !bg-gray !self-stretch  !shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] !rounded-lg !flex !flex-row !justify-start !py-[15px] ! px-3.5 !box-border !font-text-sm-medium !font-medium !text-sm !text-white`}
  //                                         name={item.id}
  //                                         onBlur={field.onBlur}
  //                                         onChange={field.onChange}
  //                                         placeholder={item.placeholder}
  //                                         type={item.type}
  //                                         value={field.value || ''}
  //                                         disabled={!isEditable}
  //                                         pattern={item.id === 'nickName' ? '[a-z0-9-]*' : '[a-zA-Z0-9 -]*'}
  //                                       />
  //                                     )}
  //                                   </>
  //                                 </FormControl>
  //                                 <FormMessage />
  //                               </FormItem>
  //                             );
  //                           }}
  //                         />
  //                       )
  //                     }
  //                   </div>
  //                 );
  //               })}

  //               <Button
  //                 type="submit"
  //                 className="block w-full rounded-lg bg-sivarbet-primary overflow-hidden flex-row items-center justify-center py-3 px-5 text-center text-sivarbet-border hover:bg-sivarbet-primary-hover"
  //               >
  //                 <div className="relative text-sm leading-3 font-sans text-sivarbet-border text-center inline-block min-w-16">
  //                   Siguiente
  //                 </div>
  //               </Button>
  //             </form>
  //           </Form>
  //         </section>
  //       );
  //   }
  // };

  // eslint-disable-next-line no-console
  return (
    <div className="w-full h-[auto] md:py-5 flex flex-col gap-5 rounded-lg">
      <section className="mt-4 w-full flex flex-col items-start justify-start gap-3 text-left text-lg text-sivarbet-text font-sans">
        <div className="w-full h-px box-border border-t border-sivarbet-border" />
      </section>
      {/* {renderCurrentStep()} */}
    </div>
  );
};

export default BankDepositForm;
