import { create } from 'zustand';
import { persist, createJSONStorage } from 'zustand/middleware';

import { Account } from '@/features/dashboard/interface';

interface UserAccount {
  address: string | undefined;
  bornDay: string | undefined;
  city: string | undefined;
  email: string | undefined;
  typeBonusSelected: string | undefined;
  emailVerified: boolean | undefined;
  firstDeposit: boolean | undefined;
  fullProfile: boolean | undefined;
  kycLevel: number | undefined;
  kycStatus: number | undefined;
  lastName: string | undefined;
  name: string | undefined;
  nick: string | undefined;
  phone: string | undefined;
  provider: number | undefined;
  topup_id: string | undefined;
  dni: string | undefined;
  favoriteGames: string[] | undefined;
  contingencies: {
    casino: boolean | undefined;
    bets: boolean | undefined;
    deposits: boolean | undefined;
    withdrawals: boolean | undefined;
  },
}

const AccountInitialState: UserAccount = {
  address: undefined,
  bornDay: undefined,
  city: undefined,
  email: undefined,
  typeBonusSelected: undefined,
  emailVerified: undefined,
  firstDeposit: undefined,
  fullProfile: undefined,
  kycLevel: undefined,
  kycStatus: undefined,
  lastName: undefined,
  name: undefined,
  nick: undefined,
  phone: undefined,
  provider: undefined,
  topup_id: undefined,
  dni: undefined,
  favoriteGames: undefined,
  contingencies: {
    casino: undefined,
    bets: undefined,
    deposits: undefined,
    withdrawals: undefined,
  },
};

export interface AuthState {
  account: UserAccount;
  setAccount: (data: UserAccount) => void;

  typeBonusSelected: string | undefined;
  setTypeBonusSelected: (type: string) => void;

  accounts: Account[];
  addAccount: (currentAccount: Account[]) => void;
  clearAccounts: () => void;
  removeAccount: (token: string) => void;

  balance: any[];
  setBalance: (value: any[]) => void;

  isxtremepushSet: boolean;
  setXtremepush: () => void;

  bonuses: {
    kycStatus: number | undefined;
    kycLevel: number | undefined;
    fullProfile: boolean | undefined;
    sport: any[];
    spins: any[];
  };
  setBonuses: (bonuses: { sport: any[]; spins: any[] }) => void;

  clearStore: () => void;

  notifyAccepted: boolean;
  setNotifyAccepted: () => void;
  selectAccount: (account: Account | null) => void;
  selectedAccount: Account | null;
  clearSelectedAccount: () => void;

  btnStateMetaMap: {
    status: boolean;
    date: Date | null;
  };
  setBtnStateMetaMap: (btnStateMetaMap: { status: boolean; date: Date | null }) => void;

  updateFavoriteGames: (favoriteGames: string[]) => void;
}

export const useAuthStore = create<AuthState>()(
  persist(
    (set, get) => {
      return {
        // To current user account
        account: AccountInitialState,
        setAccount: (data: UserAccount) => { set({ account: data }); },
        updateFavoriteGames: (favoriteGames: string[]) => {
          set({ account: { ...get().account, favoriteGames } });
        },
        // End, current user account

        // To typeBonusSelected
        typeBonusSelected: undefined,
        setTypeBonusSelected: (type: string) => { set({ typeBonusSelected: type }); },
        // End, to typeBonusSelected

        // To accounts
        accounts: [],
        addAccount: (currentAccount: Account[]) => {
          return set({ accounts: currentAccount });
        },
        clearAccounts: () => {
          return set({ accounts: [] });
        },
        removeAccount: (token: string) => {
          const accounts: Account[] = get().accounts.filter((account) => {
            return (account.token !== token);
          });
          set({ accounts });
        },
        // End, to accounts

        // To balance
        balance: [],
        setBalance: (value: any[]) => { set({ balance: value }); },
        // End, to balance

        // To isxtremepushSet
        isxtremepushSet: false,
        setXtremepush: () => { set({ isxtremepushSet: true }); },
        // End, to isxtremepushSet

        // To bonuses
        bonuses: {
          kycStatus: undefined,
          kycLevel: undefined,
          fullProfile: undefined,
          sport: [],
          spins: [],
        },
        setBonuses: (bonuses: {
          sport: any[];
          spins: any[];
          kycStatus?: number;
          kycLevel?: number;
          fullProfile?: boolean;
        }) => {
          set({
            bonuses: {
              ...get().bonuses,
              ...bonuses,
            },
          });
        },
        // End, to bonuses

        // To clear store
        clearStore: () => {
          // localStorage.removeItem('IT_account');
          set({
            account: AccountInitialState,
            accounts: [],
            balance: [],
            isxtremepushSet: false,
            notifyAccepted: false,
          });
        },
        // End, to clear store

        // To notifyAccepted
        notifyAccepted: false,
        setNotifyAccepted: () => { set({ notifyAccepted: true }); },
        // End, to notifyAccepted
        selectAccount: (account: Account | null) => {
          if (account) {
            return set({ selectedAccount: account });
          }
          return set({ selectedAccount: null });
        },
        selectedAccount: null,
        clearSelectedAccount: () => {
          return set({ selectedAccount: undefined });
        },

        btnStateMetaMap: {
          status: false,
          date: null,
        },
        setBtnStateMetaMap: (btnStateMetaMap: { status: boolean; date: Date | null }) => { return set(() => { return { btnStateMetaMap }; }); },
      };
    },
    {
      name: 'IT_account',
      storage: createJSONStorage(() => { return localStorage; }),
    },
  ),
);
