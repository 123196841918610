import { useState, useEffect } from 'react';

export function useScrollTransparency(threshold = 100) {
  const [isTransparent, setIsTransparent] = useState(true);

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollY = window.scrollY;
      if (currentScrollY > threshold) {
        setIsTransparent(false);
      } else {
        setIsTransparent(true);
      }
    };

    window.addEventListener('scroll', handleScroll, { passive: true });

    return () => { return window.removeEventListener('scroll', handleScroll); };
  }, [threshold]);

  return isTransparent;
}
