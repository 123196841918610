'use client';

import React, { useState, useEffect } from 'react';

import Image, { StaticImageData } from 'next/image';

import { usePathname } from 'next/navigation';

import { motion } from 'framer-motion';

import { ChevronLeft } from 'lucide-react';
import { signIn } from 'next-auth/react';

import { FcGoogle } from 'react-icons/fc';
import { z } from 'zod';

import { AuthType, Vendors } from '@/enums/core';
import {
  FormEmailAndPassword,
  FormPhoneCodeAndPassword,
  FormPhoneNumber,
} from '@/features/auth/components';
import WebView from '@/features/auth/components/WebView';
import { FirebaseStatus } from '@/features/auth/enums';
import { messages } from '@/features/auth/localization';

import { handleSendPhoneCode } from '@/features/auth/services';
import BonusSelection from '@/features/Bonus/BonusSelection';
import { validateEmail } from '@/features/casino/utils';
import { core, errors } from '@/localization';

import { useAuthStore } from '@/stores/userStore';
import { userUIStore } from '@/stores/userUIStore';

import { resendEmailVerification } from '@/utils/core';
import {
  handleRegisterWithEmailAndPassword,
  handleRegisterWithGoogle,
} from '@/utils/firebaseOptions';

import { useToast } from '@/components/ui/use-toast';

import {
  IconBall,
  IconDice,
  bonoCasino,
  bonoSport,
  bonoCasinoMovil,
  bonoSportMovil,
  // loginBanner,
  // loginBannerMovil,
  defaultBanner,
  defaultBannerMovil,
} from '@/public/img/marketing/promotions';

import { Button } from '../ui/button';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogTrigger,
} from '../ui/dialog';
import { ScrollArea } from '../ui/scroll-area';
/* eslint-disable max-lines-per-function */

interface Props {
  isWebView: boolean;
  isDefaultOpen: boolean;
}
const handleRegistrationStart = () => {
  if (typeof window !== 'undefined' && window.srtmCommands) {
    window.srtmCommands.push({
      event: 'track.user.registration',
      payload: { action: 'start' },
    });
  }
};

const diceVariants = {
  initial: { rotate: 0 },
  animate: {
    rotate: [0, 360, 720, 1080, 1440, 1800, 1440, 1080, 720, 360, 0],
    transition: {
      duration: 9,
      ease: [0.83, 0, 0.17, 1],
      repeat: Infinity,
      repeatType: 'loop' as const,
    },
  },
};

const footballVariants = {
  initial: { y: 0 },
  animate: {
    y: [0, -7, 10],
    transition: {
      duration: 1,
      repeat: Infinity,
      repeatType: 'loop' as const,
    },
  },
};

const handleRegistrationComplete = (userId: string) => {
  if (typeof window !== 'undefined' && window.srtmCommands) {
    window.srtmCommands.push({
      event: 'track.user.registration',
      payload: { action: 'complete', userId },
    });
  }
};

const RegisterModal = ({ isDefaultOpen, isWebView }: Props) => {
  const [isMobile, setIsMobile] = useState(false);
  const [isMobileForm, setIsMobileForm] = useState(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [phoneNumber, setPhoneNumber] = useState<string>('');
  const [opt, setOpt] = useState<any>(null);
  const [hasStartedRegistration, setHasStartedRegistration] = useState(false);
  const [welcomeBonus, setWelcomeBonus] = useState<string | null>(null);
  const [bonusSelection, setBonusSelection] = useState<boolean>(false);
  const pathname = usePathname();
  // const searchParams = useSearchParams();
  // const urlBonusParam = searchParams.get(process.env.NEXT_PUBLIC_URL_VAR_BONUS_PARAM!!);
  const { setTypeBonusSelected } = useAuthStore();
  const [imageToShow, setImageBanner] = useState<StaticImageData>(
    isMobile ? defaultBannerMovil : defaultBanner,
  );

  const handleFormInteraction = () => {
    if (!hasStartedRegistration) {
      handleRegistrationStart();
      setHasStartedRegistration(true); // Ensures event triggers only once
    }
  };

  const handleBonusSelection = () => {
    setBonusSelection(true);
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768); // Consider mobile if width <= 768px
    };

    handleResize(); // Initialize on first render
    window.addEventListener('resize', handleResize);

    // Cleanup event listener
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    const imageBanner: Record<string, StaticImageData> = {
      casino: isMobile ? bonoCasinoMovil : bonoCasino,
      sports: isMobile ? bonoSportMovil : bonoSport,
      none: isMobile ? defaultBannerMovil : defaultBanner,
      null: isMobile ? defaultBannerMovil : defaultBanner,
    };
    setImageBanner(imageBanner[welcomeBonus || 'none' || 'null']);
    if (welcomeBonus && welcomeBonus !== '') setTypeBonusSelected(welcomeBonus);
  }, [welcomeBonus, setTypeBonusSelected, isMobile]);

  const {
    isRegisterModalOpen,
    toggleRegisterModal,
    toggleLoginModal,
    isCheckboxChecked,
  } = userUIStore();

  const { toast } = useToast();

  // REGISTER
  const handleSendCodeToPhone = async (values: z.infer<any>): Promise<void> => {
    try {
      setLoading(true);
      const currentPhoneNumber = `+${values.phone}`;
      setPhoneNumber(currentPhoneNumber);

      const result = await handleSendPhoneCode(currentPhoneNumber);
      if (result && typeof result === 'object' && 'verificationId' in result) setOpt(result);
      else if (
        result
        && typeof result === 'object'
        && 'message' in result
        && 'className' in result
      ) toast({ title: result.message, className: result.className });
      else toast({ title: errors.tryAgain, variant: 'destructive' });
    } catch (error) {
      setLoading(false);
      toast({ title: errors.tryAgain, variant: 'destructive' });
    } finally {
      setLoading(false);
    }
  };

  const handleGoogleRegister = async (): Promise<void> => {
    try {
      if (!isCheckboxChecked) {
        toast({ title: messages.errorCheckbox, variant: 'destructive' });
        return;
      }
      setLoading(true);
      handleFormInteraction();
      const credential = await handleRegisterWithGoogle();

      if (credential && typeof credential !== 'string' && credential.user) {
        const displayName = credential.user?.displayName?.split(' ');
        handleRegistrationComplete(credential.user?.uid || '');

        const options = {
          callbackUrl: '/dashboard?reg=1',
          email: credential.user?.email,
          password: credential.user?.uid,
          image: credential.user?.photoURL ?? '',
          lastName: displayName?.[1] ?? '',
          name: displayName?.[0] ?? '',
          parent: 0,
          type: AuthType.REGISTER,
          vendor: Vendors.GOOGLE,
          gift: welcomeBonus,
          pathname,
        };

        await signIn('credentials', options);
      } else if (
        typeof credential === 'string'
        && credential === FirebaseStatus.AUTH_POPUP_CLOSED_BY_USER
      ) {
        setLoading(false);
        toast({
          title: messages.errorPopupClosedByUser,
          variant: 'destructive',
        });
      } else {
        setLoading(false);
        toast({ title: errors.tryAgain, variant: 'destructive' });
      }
    } catch (error) {
      setLoading(false);
      toast({ title: errors.tryAgain, variant: 'destructive' });
    }
  };

  const handleCheckPhoneCodeAndRegisterWithPhone = async (
    values: z.infer<any>,
  ): Promise<void> => {
    try {
      setLoading(true);
      handleFormInteraction();
      const phoneCredential = await opt?.confirm(values.opt);
      // eslint-disable-next-line no-console
      console.log('🚀 ~ RegisterModal ~ phoneCredential1:', phoneCredential);

      if (phoneCredential) {
        // eslint-disable-next-line no-console
        console.log('🚀 ~ RegisterModal ~ phoneCredential2:', phoneCredential);
        handleRegistrationComplete(phoneCredential.user?.uid || '');
        const options = {
          email: phoneNumber,
          password: phoneCredential.user.uid,
          callbackUrl: '/dashboard',
          type: AuthType.REGISTER,
          vendor: Vendors.PHONE,
          gift: welcomeBonus,
          pathname,
        };
        await signIn('credentials', options);
      } else {
        setLoading(false);
        toast({ title: messages.errorVerifyPhoneCode, variant: 'destructive' });
      }
    } catch (error) {
      setLoading(false);
      toast({ title: errors.tryAgain, variant: 'destructive' });
    }
  };

  const handleEmailAndPassRegister = async (
    values: z.infer<any>,
  ): Promise<void> => {
    try {
      setLoading(true);
      handleFormInteraction();
      if (values.email && values.password && values.ageVerification) {
        const credential = await handleRegisterWithEmailAndPassword(
          values.email,
          values.password,
        );
        if (credential && typeof credential !== 'string' && credential.user) {
          if (credential.user?.email && validateEmail(credential.user?.email)) {
            toast({ title: 'Este dominio de email no esta permitido', variant: 'destructive' });
            return;
          }
          handleRegistrationComplete(credential.user?.uid || '');
          const options = {
            email: credential.user?.email,
            password: credential.user?.uid,
            callbackUrl: '/dashboard?reg=1',
            type: AuthType.REGISTER,
            vendor: Vendors.EMAIL,
            gift: welcomeBonus,
            pathname,
          };
          await signIn('credentials', options);

          const isEmailSent = await resendEmailVerification();
          if (isEmailSent) {
            toast({
              title: messages.emailSent,
              className: 'bg-sivarbet-primary text-sivarbet-background',
            });
          }
        } else if (
          credential
          && typeof credential === 'string'
          && credential === FirebaseStatus.AUTH_EMAIL_EXISTS
        ) {
          setLoading(false);
          toast({
            title: messages.errorUserRegistered,
            variant: 'destructive',
          });
        } else {
          setLoading(false);
          toast({ title: errors.tryAgain, variant: 'destructive' });
        }
      } else {
        setLoading(false);
        toast({ title: errors.tryAgain, variant: 'destructive' });
      }
    } catch (error) {
      setLoading(false);
      toast({ title: errors.tryAgain, variant: 'destructive' });
    }
  };

  return (
    <Dialog
      modal={false}
      defaultOpen={isDefaultOpen}
      open={isRegisterModalOpen}
      onOpenChange={toggleRegisterModal}
    >
      {!isDefaultOpen && (
        <DialogTrigger asChild>
          <Button className="primary-bg-btn text-sivarbet-background font-semibold" size="sm" variant="secondary">
            {messages.register}
          </Button>
        </DialogTrigger>
      )}
      <DialogContent
        onOpenAutoFocus={(e) => {
          return e.preventDefault();
        }}
        onInteractOutside={(e) => {
          e.preventDefault();
        }}
        className="bg-sivarbet-secondary-bg border-none p-0 flex flex-col gap-0 lg:flex-row lg:flex-nowrap lg:max-w-[816px] lg:max-h-[630px] h-full bg-gradient-to-br from-[#29ABE2]/20 to-black rounded-lg"
      >
        <div
          className={`relative ${isMobile ? 'w-full h-[200px] aspect-[9/16]' : 'w-1/2 aspect-[16/9]'} overflow-hidden`}
        >
          <Image
            src={imageToShow}
            alt="register"
            objectFit="contain"
            objectPosition="center"
            sizes="(max-width: 768px) 100vw, 50vw"
            priority
          />
        </div>
        {!bonusSelection ? (
          <BonusSelection
            welcomeBonus={welcomeBonus || ''}
            setWelcomeBonus={setWelcomeBonus}
            handleBonusSelection={handleBonusSelection}
          />
        ) : (
          <ScrollArea className="lg:w-[50%] flex flex-col justify-center h-full ov pb-5">
            {isWebView ? (
              <WebView />
            ) : (
              <>
                {(welcomeBonus === 'casino' || welcomeBonus === 'sports') && (
                  <div className="flex items-center justify-between w-full bg-sivarbet-primary p-3 lg:mt-12">
                    <div className="text-sivarbet-border flex items-center gap-2">
                      <ChevronLeft
                        className="text-sivarbet-border cursor-pointer hover:text-sivarbet-background"
                        onClick={() => {
                          setBonusSelection(false);
                          setWelcomeBonus('');
                        }}
                      />
                      <span>
                        {welcomeBonus === 'casino' ? 'Casino: 100 Giros Gratis' : 'Deportes: 100 HNL Gratis'}
                      </span>
                    </div>
                    <div className="flex items-center space-x-2">
                      <motion.div
                        variants={
                          welcomeBonus === 'casino' ? diceVariants : footballVariants
                        }
                        initial="initial"
                        animate="animate"
                      >
                        <Image
                          src={welcomeBonus === 'casino' ? IconDice : IconBall}
                          alt="IconBall"
                          width={20}
                          height={20}
                        />
                      </motion.div>
                    </div>
                  </div>
                )}
                <DialogTitle className="flex justify-start relative text-inherit leading-[20px] font-medium font-inherit mx-[17px] mb-8 mt-2">
                  {`${messages.registerTo} ${core.appName}`}
                </DialogTitle>
                <div className="h-[11px] relative tracking-[-0.01em] leading-[20px] inline-block mx-[17px] max-lg:hidden mb-6">
                  <span className="mr-2">{messages.alreadyHaveAnAccount}</span>
                  <span
                    onClick={() => {
                      toggleRegisterModal();
                      toggleLoginModal();
                    }}
                    className="[text-decoration:underline] text-sivarbet-primary cursor-pointer"
                  >
                    {messages.enterHere}
                  </span>
                </div>

                {isMobileForm && !opt && (
                  <FormPhoneNumber
                    action={handleSendCodeToPhone}
                    disabled={loading}
                  />
                )}
                {isMobileForm && opt && (
                  <FormPhoneCodeAndPassword
                    action={handleCheckPhoneCodeAndRegisterWithPhone}
                    disabled={loading}
                    isRegister
                  />
                )}
                {!isMobileForm && (
                  <FormEmailAndPassword
                    action={handleEmailAndPassRegister}
                    disabled={loading}
                    isRegister
                  />
                )}

                <div className="h-[11px] relative tracking-[-0.01em] leading-[20px] inline-block mx-[17px] lg:hidden mb-5">
                  <span>{messages.alreadyHaveAnAccount}</span>
                  <span
                    onClick={() => {
                      toggleRegisterModal();
                      toggleLoginModal();
                    }}
                    className="[text-decoration:underline] text-sivarbet-primary cursor-pointer"
                  >
                    {messages.enterHere}
                  </span>
                </div>
                <div className="self-stretch flex flex-col items-start justify-start gap-[30px] text-mini text-third_text mx-[17px]">
                  <div className="self-stretch flex flex-row flex-wrap items-start justify-start gap-[10px]">
                    <div className="center-box">
                      <div className="self-stretch h-px relative box-border border-t-[1px] border-solid border-sivarbet-border" />
                    </div>
                    <div className="relative leading-[11px] inline-block min-w-[104px]">
                      {messages.orRegisterWith}
                    </div>
                    <div className="center-box">
                      <div className="self-stretch h-px relative box-border border-t-[1px] border-solid border-sivarbet-border" />
                    </div>
                  </div>
                  <div className="self-stretch flex flex-col items-start justify-start gap-[10px]">
                    <Button
                      disabled={loading}
                      onClick={() => {
                        setIsMobileForm(!isMobileForm);
                      }}
                      type="button"
                      className="cursor-pointer [border:none] py-[10.5px] px-5 bg-sivarbet-background self-stretch rounded-lg overflow-hidden flex flex-row items-center justify-center gap-[8px]"
                    >
                      <span
                        className={`icon icon-${!isMobileForm ? 'phone' : 'mail'} text-sivarbet-primary mt-1 mr-2`}
                      />
                      <div className="w-[145px] flex items-center justify-center pt-[4.5px] px-0 pb-0 box-border">
                        <div className="self-stretch h-2.5 relative text-sm leading-[20px] font-medium font-text-sm-medium text-sivarbet-primary text-center flex items-center justify-center">
                          {`${messages.registerWith} ${isMobileForm ? messages.yourEmail.toLocaleLowerCase() : messages.yourPhone.toLocaleLowerCase()}`}
                        </div>
                      </div>
                    </Button>
                    <Button
                      disabled={loading}
                      onClick={handleGoogleRegister}
                      type="button"
                      className="cursor-pointer [border:none] py-[10.5px] px-5 bg-sivarbet-background self-stretch rounded-lg overflow-hidden flex flex-row items-center justify-center gap-[8px]"
                    >
                      {/* <span className="icon icon-google text-sivarbet-primary mt-1" /> */}
                      <FcGoogle className="text-lg" />

                      <div className="w-[145px] flex items-center justify-center pt-[4.5px] px-0 pb-0 box-border">
                        <div className="self-stretch h-2.5 relative text-sm leading-[20px] font-medium font-text-sm-medium text-sivarbet-primary text-center flex items-center justify-center">
                          {`${messages.registerWith} ${messages.google}`}
                        </div>
                      </div>
                    </Button>
                  </div>
                </div>
              </>
            )}
          </ScrollArea>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default RegisterModal;
