/* eslint-disable react/jsx-props-no-spreading */
import { memo, useEffect, useState } from 'react';

import { format } from 'date-fns';
import { es } from 'date-fns/locale/es';
import { CalendarIcon } from 'lucide-react';
// import { Session } from 'next-auth';
import { registerLocale } from 'react-datepicker';
import { UseFormReturn } from 'react-hook-form';
import { z } from 'zod';

import { messages } from '@/features/modals/localization';

import { cn } from '@/lib/utils';

import { Button } from '@/components/ui/button';
import { Calendar } from '@/components/ui/calendar';
import { DialogClose } from '@/components/ui/dialog';
import {
  Form,
  FormControl, FormField, FormItem, FormLabel, FormMessage,
} from '@/components/ui/form';
import { Input } from '@/components/ui/input';
import { Popover, PopoverTrigger, PopoverContent } from '@/components/ui/popover';
import {
  Select, SelectTrigger, SelectValue, SelectContent, SelectItem,
} from '@/components/ui/select';

interface Props {
  action: (values: z.infer<any>) => void,
  form: UseFormReturn<any, any, undefined>,
  // eslint-disable-next-line react/require-default-props
  account?: any;
}

const FormRegisterPopUp = ({ action, form, account }:Props) => {
  const [isCalendarOpen, setIsCalendarOpen] = useState(false);
  registerLocale('es', es);

  const updateProfile = async () => {
    const isValid = await form.trigger();
    if (isValid) {
      document.getElementById('close-modal-button')?.click();
    }
  };

  useEffect(() => {
    if (account && account.name && account.lastName) {
      form.setValue('name', account.name?.split(' ')[0]);
      form.setValue('secondname', account.name?.split(' ')[1]);
      form.setValue('lastname', account.lastName);
    }
  }, [form, account]);

  return (
    <Form
      watch={form.watch}
      getValues={form.getValues}
      getFieldState={form.getFieldState}
      setError={form.setError}
      clearErrors={form.clearErrors}
      setValue={form.setValue}
      trigger={form.trigger}
      formState={form.formState}
      resetField={form.resetField}
      reset={form.reset}
      handleSubmit={form.handleSubmit}
      unregister={form.unregister}
      control={form.control}
      register={form.register}
      setFocus={form.setFocus}
    >
      <form
        onSubmit={form.handleSubmit(action)}
        className="space-y-4 mx-5 my-3"
      >
        <FormField
          control={form.control}
          name="name"
          render={() => {
            return (
              <FormItem>
                <FormLabel>{messages.firstName}</FormLabel>
                <FormControl>
                  <Input
                    name="name"
                    defaultValue={account?.name?.split(' ')[0]}
                    placeholder={messages.firstName}
                    className="input-form-text"
                    onChange={(e) => {
                      form.setValue('name', e.target.value);
                    }}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            );
          }}
        />
        <FormField
          control={form.control}
          name="secondname"
          render={() => {
            return (
              <FormItem>
                <FormLabel>{messages.secondName}</FormLabel>
                <FormControl>
                  <Input
                    name="secondname"
                    defaultValue={account?.name?.split(' ')[1]}
                    placeholder={messages.secondName}
                    className="input-form-text"
                    onChange={(e) => {
                      form.setValue('secondname', e.target.value);
                    }}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            );
          }}
        />
        <FormField
          control={form.control}
          name="lastname"
          render={() => {
            return (
              <FormItem>
                <FormLabel>{messages.lastName}</FormLabel>
                <FormControl>
                  <Input
                    name="lastname"
                    defaultValue={account?.last_name}
                    placeholder={messages.lastName}
                    className="input-form-text"
                    onChange={(e) => {
                      form.setValue('lastname', e.target.value);
                    }}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            );
          }}
        />
        <FormField
          control={form.control}
          name="birthdate"
          render={({ field }) => {
            return (
              <FormItem className="flex flex-col">
                <FormLabel>{messages.birthdate}</FormLabel>
                <Popover open={isCalendarOpen} onOpenChange={setIsCalendarOpen}>
                  <PopoverTrigger asChild>
                    <FormControl>
                      <Button
                        variant="outline"
                        className={cn(
                          'w-full pl-3 text-left font-normal bg-gray border-none hover:bg-gray/50 hover:text-sivarbet-text ring-offset-sivarbet-border',
                          !field.value && 'text-muted-foreground',
                        )}
                      >
                        {field.value ? (
                          format(field.value, 'dd/MM/yyyy')
                        ) : (
                          <span>{messages.birthdate}</span>
                        )}
                        <CalendarIcon className="ml-auto h-4 w-4 opacity-50" />
                      </Button>
                    </FormControl>
                  </PopoverTrigger>
                  <PopoverContent className="w-auto p-0 rounded border-none shadow-xl !relative" align="start" side="top">
                    <Calendar
                      mode="single"
                      locale={es}
                      captionLayout="dropdown-buttons"
                      fromYear={1920}
                      toYear={new Date().getFullYear()}
                      selected={field.value}
                      onSelect={field.onChange}
                      disabled={(date) => { return date > new Date() || date < new Date('1920-01-01'); }}
                      onDayClick={() => { setIsCalendarOpen(false); }}
                      className="bg-gray text-sivarbet-text rounded"
                      classNames={{
                        day: 'text-sivarbet-text bg-transparent hover:bg-transparent focus:bg-transparent hover:rounded focus:rounded',
                        day_disabled: 'text-sivarbet-text/50',
                        caption_dropdowns: 'flex justify-evenly items-center bg-gray text-sivarbet-text',
                        caption: 'bg-gray text-sivarbet-text',
                        caption_label: 'hidden ',
                        vhidden: 'hidden',
                        dropdown_icon: 'hidden',
                        dropdown: 'bg-sivarbet-background text-sivarbet-text rounded p-1',
                        cell: 'rounded content-center h-9 w-9 text-center text-sm p-0 relative [&:has([aria-selected].day-range-end)]:rounded-r-md [&:has([aria-selected].day-outside)]:bg-sivarbet-primary/50 [&:has([aria-selected])]:bg-sivarbet-primary first:[&:has([aria-selected])]:rounded-l-md last:[&:has([aria-selected])]:rounded-r-md focus-within:relative focus-within:z-20',
                      }}
                    />
                  </PopoverContent>
                </Popover>
                <FormMessage />
              </FormItem>
            );
          }}
        />
        {/*     <FormField
          control={form.control}
          name="country"
          render={({ field }) => {
            return (
              <FormItem>
                <FormLabel>{messages.country}</FormLabel>
                <Select onValueChange={field.onChange} defaultValue={field.value}>
                  <FormControl>
                    <SelectTrigger className="bg-gray border-none ring-offset-sivarbet-border">
                      <SelectValue placeholder={messages.selectCountry} />
                    </SelectTrigger>
                  </FormControl>
                  <SelectContent className="bg-gray">
                    <SelectItem value="hn" className="bg-gray hover:bg-gray/50 hover:text-sivarbet-text ring-offset-sivarbet-border focus:bg-gray/50 focus:text-sivarbet-text">Honduras</SelectItem>
                  </SelectContent>
                </Select>
                <FormMessage />
              </FormItem>
            );
          }}
        /> */}
        <FormField
          control={form.control}
          name="clubFavorite"
          render={({ field }) => {
            return (
              <FormItem>
                <FormLabel>{messages.clubFavorite}</FormLabel>
                <Select onValueChange={field.onChange} defaultValue={field.value}>
                  <FormControl>
                    <SelectTrigger className="bg-gray border-none ring-offset-sivarbet-border">
                      <SelectValue placeholder={messages.selectClub} />
                    </SelectTrigger>
                  </FormControl>
                  <SelectContent className="bg-gray ">
                    <SelectItem value="realespaña" className="bg-gray hover:bg-gray/50 text-white hover:text-sivarbet-primary ring-offset-sivarbet-border focus:bg-gray/50 focus:text-sivarbet-primary">Real España</SelectItem>
                    <SelectItem value="motagua" className="bg-gray hover:bg-gray/50 text-white hover:text-sivarbet-primary ring-offset-sivarbet-border focus:bg-gray/50 focus:text-sivarbet-primary">Motagua</SelectItem>
                    <SelectItem value="victoria" className="bg-gray hover:bg-gray/50 text-white hover:text-sivarbet-primary ring-offset-sivarbet-border focus:bg-gray/50 focus:text-sivarbet-primary">Victoria</SelectItem>
                    <SelectItem value="olimpia" className="bg-gray hover:bg-gray/50 text-white hover:text-sivarbet-primary ring-offset-sivarbet-border focus:bg-gray/50 focus:text-sivarbet-primary">Olimpía</SelectItem>
                    <SelectItem value="marathon" className="bg-gray hover:bg-gray/50 text-white hover:text-sivarbet-primary ring-offset-sivarbet-border focus:bg-gray/50 focus:text-sivarbet-primary">Marathon</SelectItem>
                    <SelectItem value="lobos" className="bg-gray hover:bg-gray/50 text-white hover:text-sivarbet-primary ring-offset-sivarbet-border focus:bg-gray/50 focus:text-sivarbet-primary">Lobos UPNFM</SelectItem>
                    <SelectItem value="olancho" className="bg-gray hover:bg-gray/50 text-white hover:text-sivarbet-primary ring-offset-sivarbet-border focus:bg-gray/50 focus:text-sivarbet-primary">Olancho</SelectItem>
                    <SelectItem value="juticalpa" className="bg-gray hover:bg-gray/50 text-white hover:text-sivarbet-primary ring-offset-sivarbet-border focus:bg-gray/50 focus:text-sivarbet-primary">Juticalpa</SelectItem>
                    <SelectItem value="realsociedad" className="bg-gray hover:bg-gray/50 text-white hover:text-sivarbet-primary ring-offset-sivarbet-border focus:bg-gray/50 focus:text-sivarbet-primary">Real Sociedad</SelectItem>
                    <SelectItem value="genesis" className="bg-gray hover:bg-gray/50 text-white hover:text-sivarbet-primary ring-offset-sivarbet-border focus:bg-gray/50 focus:text-sivarbet-primary">Genesis</SelectItem>
                  </SelectContent>
                </Select>
                <FormMessage />
              </FormItem>
            );
          }}
        />
        <div className="w-full text-center">
          <Button className="w-1/2 mt-2 bg-sivarbet-primary text-sivarbet-text hover:bg-sivarbet-primary-hover" type="submit" onClick={updateProfile}>Guardar</Button>
          <DialogClose asChild>
            <Button id="close-modal-button" className="hidden" />
          </DialogClose>
        </div>
      </form>
    </Form>
  );
};

export default memo(FormRegisterPopUp);
